<template>
  <loader v-bind="{ loading }" text="Loading Equipment Classifications">
    <columns>
      <column>
        <page-heading
          heading="Equipment Classifications"
        />
      </column>
      <column class="is-5">
        <form>
          <text-input 
            v-model="filters.search"
            classes="has-addons is-rounded"
            placeholder="Search checkpoints...">
            <template #right>
              <div class="buttons has-addons is-rounded">
                <submit-button 
                  @submit="runSearch"
                  class="is-rounded">
                  Search
                </submit-button>
                <action-button
                  v-if="$route.query.search"
                  @click="clearFilters">
                  <icon icon="times"/> 
                </action-button>
                <router-link 
                  class="button is-rounded is-flex" 
                  :to="{
                    name: 'create-equipment-classification'
                  }">
                    <icon icon="plus"/>
                    <span>Classification</span>
                  </router-link>
              </div>
            </template>
          </text-input>
        </form>
      </column>
    </columns>
    <columns>
      <column>
        <div class="index-rows">
          <div class="box is-marginless" v-for="equipmentClassification in classifications.data"
            :key="equipmentClassification.id">
            <columns>
              <column>
                <router-link :to="{
                  name: 'equipment-classification-manager',
                  params: {
                    equipmentClassification: equipmentClassification.uuid
                  }
                }">
                  {{ equipmentClassification.name }}
                </router-link>
                <p class="is-flex">
                  <small class="has-text-grey">
                    Updated {{ equipmentClassification.updated_at | asCalendar }}
                  </small>
                  <span 
                    v-tooltip="`This classification is only accessible by the segemented teams(${equipmentClassification.segmented_teams_count}) it is assigned to.`"
                    class="has-text-warning" 
                    v-if="equipmentClassification.segmented_teams_count">
                    <icon 
                      icon="exclamation-triangle"
                    />
                  </span>
                </p>
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <index-info 
                  icon="hashtag" 
                  label="Inventory" 
                  :info="equipmentClassification.equipment_count"
                />
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <index-info 
                  icon="list-check" 
                  label="Checklists" 
                  :info="equipmentClassification.checklists_count"
                />
              </column>
            </columns>
          </div>
        </div>

        <pager v-if="classifications.data.length" :pageable="classifications" context="Equipment Classification" jump-controls @nav="goToPage" />

        <no-items-to-display
          :item-count="classifications.data.length"
          with-link
          heading="There are no equipment classifications available for display."
          :to="{
            name: 'create-equipment-classification'
          }"
          to-text="New Equipment Classification"
        />

      </column>
    </columns>
  </loader>
</template>
<script>
import { get } from '@/api/request'
export default {

  data: () => ({
    loading: true,
    filters: {
      search: ''
    },
    classifications: {
      data: []
    }
  }),

  created() {
    if (this.$route.query) {
      this.filters.search = this.$route.query.search
    }
    this.loadequipmentClassifications()
  },

  methods: {
    loadequipmentClassifications() {
      get('v1' + this.$route.fullPath, ({ data }) => {
        this.classifications = data
        this.loading = false
      }, () => {
        this.loading = false
      })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    runSearch() {
      this.$router.push({
        name: 'equipment-classifications',
        query: this.filters
      });
    },
    clearFilters() {
      this.filters.search = ''
      this.$router.push({
        name: 'equipment-classifications',
      });
    }
  },

  watch: {
    '$route': 'loadequipmentClassifications'
  }

}
</script>